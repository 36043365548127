.wallet--connection-modal{
    position: fixed;
    width: 100%;
    max-width: 800px;
    min-width: fit-content;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    border-radius: 12px;
    z-index: 1000;
    background-color: #fff;
}

.wallet--connection-modal_item_wrapper {
    width: 100%;
    padding: 8px;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: column;
    cursor: pointer;
    border-bottom: 1px solid rgba(195, 195, 195, 0.14);
}

.wallet--connection-modal_item {
    transition: background-color 0.2s ease-in-out 0s;
    width: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    border-radius: 12px;
    padding: 24px 16px;
    text-align: center;
}

.wallet--connection-modal_item:hover {
    background-color: rgba(195, 195, 195, 0.14);
}

.wallet--connection-modal_img {
    width: 45px;
    height: 45px;
    display: flex;
    border-radius: 50%;
    overflow: visible;
    box-shadow: none;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
}

.wallet--connection-modal_img img {
    width: 100%;
    height: 100%;
}

.wallet--connection-modal_provider {
    width: 100%;
    font-size: 24px;
    font-weight: 700;
    margin-top: 0.5em;
    color: #0c0c0d;
}

.wallet--connection-modal_description {
    width: 100%;
    font-size: 18px;
    margin: 0.333em 0px;
    color: #a9a9bc;
}


.wallet--connection-modal_overlay{
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.4);
}
